import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {fetchEntityData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/site";
import api from "../../../utils/api";
import {SITES_PATH} from "../../../paths/routes/admin/routePaths";
import { useNavigate } from 'react-router-dom'
import SingleSite from "../../../components/single/site/SingleSite";

import './site.scss';

const Site = () => {

    let { id } = useParams();
    const [siteData, setSiteData] = useState({})
    const navigate = useNavigate()

    const updateSite = async (data) => {
        try {
            const { city: { id: cityId }, ...otherUnitsData } = siteData;
            const dataToSend = { ...otherUnitsData, 'city_id': cityId, ...data };
            const response = await api.put(`sites/${id}`, dataToSend);
            setSiteData(response.data.site);
            displaySuccess(response.data.message);
        } catch (e) {
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntityData('sites', id, fields).then(site => {
            setSiteData(site)
        }).catch(error => {
            navigate(SITES_PATH);
            displayError(error.response.data)
        })
    }, []);

    return (
        <div className="site"><SingleSite title={siteData.name}
                                          onUpdate={updateSite}
                                          data={siteData}/></div>
    )
}
export default Site
