import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {fetchEntityData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/city";
import api from "../../../utils/api";
import {CITIES_PATH} from "../../../paths/routes/admin/routePaths";
import { useNavigate } from 'react-router-dom'
import SingleCity from "../../../components/single/city/SingleCity";

import './city.scss';

const City = () => {

    let { id } = useParams();
    const [cityData, setCityData] = useState({})
    const navigate = useNavigate()

    const updateCity = async (data) => {
        try {
            const { organization_unit: { id: organizationUnitId }, ...otherUnitsData } = cityData;
            const dataToSend = { ...otherUnitsData, 'organization_unit_id': organizationUnitId, ...data };
            const response = await api.put(`cities/${id}`, dataToSend);
            setCityData(response.data.city);
            displaySuccess(response.data.message);
        } catch (e) {
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntityData('cities', id, fields).then(city => {
            setCityData(city)
        }).catch(error => {
            navigate(CITIES_PATH);
            displayError(error.response.data)
        })
    }, []);

    return (
        <div className="city"><SingleCity title={cityData.name}
                                                       onUpdate={updateCity}
                                                       data={cityData}/></div>
    )
}
export default City
