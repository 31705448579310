import React from 'react'
import './profile.scss'

export const Profile = () => {
    return (
        <div>Profile</div>
    )
}

export default Profile
