import { z } from 'zod';

export const organizationFormSchema = z.object({
    name: z.string()
        .nonempty({ message: "Le nom est requis" })
        .max(45, { message: "Le nom ne peut pas dépasser 45 caractères" }),

    authentication_string: z.string()
        .nonempty({ message: "La chaîne d'authentification est requise" }),

    pac: z.string()
        .nonempty({ message: "Le PAC est requis" })
        .length(5, { message: "Le PAC doit contenir exactement 5 caractères" }),

    postcode: z.string()
        .nonempty({ message: "Le code postal est requis" })
        .length(5, { message: "Le code postal doit contenir exactement 5 caractères" })
});
