export const BASE_PATH = "/admin";
export const HOME_PATH = `${BASE_PATH}`;
export const PROFILE_PATH = `${BASE_PATH}/profile`;
export const USERS_PATH = `${BASE_PATH}/users`;
export const ORGANIZATIONS_PATH = `${BASE_PATH}/organizations`
export const ORGANIZATION_UNITS_PATH = `${BASE_PATH}/organization-units`
export const CITIES_PATH = `${BASE_PATH}/cities`
export const SITES_PATH = `${BASE_PATH}/sites`
export const ACTION_TYPES_PATH = `${BASE_PATH}/action-types`
export  const ROLES_PATH = `${BASE_PATH}/roles`
