import React, {useEffect} from 'react'
import './dataTable.scss'
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import {IMAGES_PATH} from "../../paths/dirs/dirPaths";
import {BASE_PATH} from "../../paths/routes/admin/routePaths";
import {Link} from "react-router-dom";



const localizedTextsMap = {

    // Columns selector toolbar button text
    toolbarColumns: 'Colonne',
    toolbarColumnsLabel: 'Selectionner colonne',

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Rechercher…',
    toolbarQuickFilterLabel: 'Recherche',
    toolbarQuickFilterDeleteIconLabel: 'Effacer',

    // Export selector toolbar button text
    toolbarExport: 'Exporter',
    toolbarExportLabel: 'Exporter',
    toolbarExportCSV: 'Télécharger en CSV',
    toolbarExportPrint: 'Imprimer',
    toolbarExportExcel: 'Télécharger en Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Trouver colonne',
    columnsPanelTextFieldPlaceholder: 'Titre de la colonne',
    columnsPanelDragIconLabel: 'Réordonner colonne',
    columnsPanelShowAllButton: 'Afficher tout',
    columnsPanelHideAllButton: 'Cacher tout',

    // Density selector toolbar button text
    toolbarDensity: 'Densité',
    toolbarDensityLabel: 'Densité',
    toolbarDensityCompact: 'Compacte',
    toolbarDensityStandard: 'Normale',
    toolbarDensityComfortable: 'Comfortable',

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Sélection de lignes',
    checkboxSelectionSelectAllRows: 'Sélectionner toute les lignes',
    checkboxSelectionUnselectAllRows: 'Déselectionner toute les lignes',
    checkboxSelectionSelectRow: 'Sélectionner ligne',
    checkboxSelectionUnselectRow: 'Déselectionner ligne',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} lignes sélectionnées`
            : `${count.toLocaleString()} ligne sélectionnée`,

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Afficher colonnes',
    columnMenuFilter: 'Filtrer',
    columnMenuHideColumn: 'Cacher',
    columnMenuUnsort: 'désordonner',
    columnMenuSortAsc: 'Trié par ordre croissant',
    columnMenuSortDesc: 'Trié par ordre décroissant',

    // Root
    noRowsLabel: 'Pas de lignes',
    noResultsOverlayLabel: 'Aucun résultat trouvé.',
    errorOverlayDefaultLabel: 'Une erreur est survenue.',

    // Filters toolbar button text
    toolbarFilters: 'Filtres',
    toolbarFiltersLabel: 'Afficher les filtres',
    toolbarFiltersTooltipHide: 'Cacher les filtres',
    toolbarFiltersTooltipShow: 'Afficher les filtres',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtres activés` : `${count} filtre activé`,

    columnsManagementSearchTitle: 'Rechercher',
    columnsManagementNoColumns: 'Aucune colonne',
    columnsManagementShowHideAllText: 'Afficher/Masquer Tout',
};

export const DataTable = ({onDelete, slug, rows, columns}) => {

    const handleDelete = (id) => {
        onDelete(id)
    }

    const actionColumn = {
        field: "actions",
        headerName: "Actions",
        width:200,
        renderCell: (params) => {
            return (
                <div className="action">
                    <Link to={`${BASE_PATH}/${slug}/${params.row.id}`}>
                        <img src={`${IMAGES_PATH}/view.svg`} alt=""/>
                    </Link>
                    <div className="delete" onClick={()=>handleDelete(params.row.id)}>
                        <img src={`${IMAGES_PATH}/delete.svg`} alt=""/>
                    </div>
                </div>
            );
        }
    }


    return (
        <div className="dataTable">
            <DataGrid
                className="dataGrid"
                rows={rows}
                columns={[...columns, actionColumn]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{toolbar:GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {debounceMs: 500},
                    }
                }}
                localeText={localizedTextsMap}
                pageSizeOptions={[10]}
                checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
            />
        </div>
    )
}
