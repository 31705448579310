import React, { useEffect, useRef, useState } from 'react';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import './numberRangeSlider.scss';

/**
 * Displays a number range slider using the noUiSlider library.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.minLeftBoundValue - The minimum value for the left bound of the slider.
 * @param {number} props.maxRightBoundValue - The maximum value for the right bound of the slider.
 * @param {string} props.leftBoundLabel - The label for the left bound of the slider.
 * @param {string} props.rightBoundLabel - The label for the right bound of the slider.
 * @returns {JSX.Element} - The rendered NumberRangeSlider component.
 *
 * @since 1.0.0
 */
const NumberRangeSlider = ({ minLeftBoundValue, maxRightBoundValue, leftBoundLabel, rightBoundLabel }) => {
    const sliderRef = useRef(null);
    const [leftBoundValue, setLeftBoundValue] = useState(minLeftBoundValue);
    const [rightBoundValue, setRightBoundValue] = useState(maxRightBoundValue);

    useEffect(() => {
        const slider = sliderRef.current;

        if (slider) {
            noUiSlider.create(slider, {
                start: [minLeftBoundValue, maxRightBoundValue],
                connect: true,
                range: {
                    min: minLeftBoundValue,
                    max: maxRightBoundValue,
                },
            });

            slider.noUiSlider.on('update', (values, handle) => {
                const [min, max] = values.map(parseFloat);
                setLeftBoundValue(Math.round(min));
                setRightBoundValue(Math.round(max));
            });
        }

        // Update the range when minLeftBoundValue or maxRightBoundValue changes
        slider.noUiSlider.updateOptions({
            range: {
                min: minLeftBoundValue,
                max: maxRightBoundValue,
            },
        });

        return () => {
            if (slider) {
                slider.noUiSlider.destroy();
            }
        };
    }, [minLeftBoundValue, maxRightBoundValue]);

    return (
        <div className="number-range-slider-container">
            <div ref={sliderRef} id="ageRange"></div>

            <div className="number-range-slider-label">
                <span id="leftBound">
                    <span>{leftBoundLabel}: </span>
                    <span id="leftBoundValue">{leftBoundValue}</span>
                </span>
                <span id="rightBound">
                    <span>{rightBoundLabel}: </span>
                    <span id="rightBoundValue">{rightBoundValue}</span>
                </span>
            </div>
        </div>
    );
};

export default NumberRangeSlider;
