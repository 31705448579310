import React from 'react';
import DropdownFilter from '../filterOptionTypes/dropdownFilter/DropdownFilter';
import NumberRangeSliderFilter from '../filterOptionTypes/numberRangeSliderFilter/NumberRangeSliderFilter';

import "./filterOption.scss";

/**
 * Renders the appropriate filter component based on the type of filter option.
 *
 * @component
 * @param {object} filterOption - Object containing filter option properties.
 * @returns {JSX.Element} - The rendered filter component.
 * 
 * @author Elio Bteich
 * @since 1.0.0
 */
const FilterOption = ({ filterOption }) => {

  // Render the correct filter component based on the type
  switch (filterOption.type.name) {
    case 'dropdown':
      return <DropdownFilter filterOption={filterOption} />;
    case 'number-range-slider':
      return <NumberRangeSliderFilter filterOption={filterOption} />;
    default:
      // Handle unsupported filter types by returning null
      return null;
  }
  
};

export default FilterOption;
