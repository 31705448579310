import { z } from 'zod';

export const siteFormSchema = z.object({
    name: z.string()
        .nonempty({ message: "Le nom est requis" })
        .max(45, { message: "Le nom ne peut pas dépasser 45 caractères" }),
    preference_index: z.coerce.number()
        .gte(1, 'L\'indice de préférence doit être supérieur ou égal à 1')
        .lte(10, 'L\'indice de préférence ne peut pas dépasser 10'),
    status: z.coerce.number()
        .gte(0, 'Le status ne peut prendre que la valeur 0 ou 1')
        .lte(1, 'Le status ne peut prendre que la valeur 0 ou 1'),
    city_id: z.string()
        .nonempty({ message: "La ville est requise" }),
    priority: z.coerce.number()
        .gte(1, 'La priorité doit être supérieur ou égal à 1')
        .lte(10, 'La priorité ne peut pas dépasser 10'),
});
