import api from '../utils/api';
import axios from 'axios';
import { displayError } from "../utils/alerts";

/**
 * Handles user authentication.
 *
 * Fetches a CSRF cookie from the server and then sends a POST login request to the server.
 * Upon successful authentication, the server sends a cookie "authToken" and returns user information along with a success message.
 *
 * @param {Object} credentials - An object containing user credentials.
 * @param {string} credentials.username - User's username.
 * @param {string} credentials.password - User's password.
 * @returns {Object} An object with user information and a success message.
 * @throws {Object} An error object if the server returns an error response.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
export const login = async (credentials) => {
    try {
        // Fetch CSRF token before the login request
        await getCsrfToken();

        const response = await api.post('/login', credentials);

        const { user, authToken, message } = response.data;

        localStorage.setItem('authToken', authToken);

        return { user, message };

    } catch (error) {
        throw error.response;
    }
};

/**
 * Retrieves the authenticated user's information.
 *
 * This function sends a GET request to fetch the authenticated user's information.
 *
 * @returns {Object} The authenticated user's information.
 * @throws {Object} An error object if the server returns an error response.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
export const getAuthUser = async () => {
    try {
        const response = await api.get('/getAuth');
        const { user } = response.data;

        return user;

    } catch (error) {
        if (error.response.status !== 401) {
            displayError(`Error during request setup or response processing: ${error.message}`);
        }

        throw error;
    }
};

/**
 * Fetches the CSRF token and sets it as a cookie.
 *
 * This function sends a GET request to fetch the CSRF token. The token is automatically
 * set as a cookie by the server.
 *
 * @returns {void}
 * @throws {Object} An error object if the server returns an error response.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
export const getCsrfToken = async () => {
    try {
        // Send a request to fetch the CSRF token as a cookie
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`);

    } catch (error) {
        console.error('Failed to fetch CSRF token', error);
        throw error;
    }
};

/**
 * Handles user logout by sending a POST request to the server.
 * It removes the authentication token from local storage
 * upon successful logout and returns a success message.
 *
 * @returns {Object} An object with a success message.
 * @throws {Object} An error object if the server returns an error response.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
export const logout = async () => {
    try {
        const response = await api.post('/logout');

        const { message } = response.data;

        localStorage.removeItem('authToken');

        return { message };

    } catch (error) {
        throw error.response.data;
    }
};
