import React, {useEffect} from 'react';
import { useForm } from "react-hook-form";
import { displayError } from "../../../../utils/alerts";
import { zodResolver } from '@hookform/resolvers/zod';
import { organizationFormSchema } from './organizationFormSchema';

import '../formModal.scss';

export const OrganizationFormModal = ({ title, buttonText, setOpen, organizationData, submitData }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(organizationFormSchema),
    });

    // Populate form data if organizationData exists (for edit mode)
    useEffect(() => {
        if (organizationData) {
            setValue("name", organizationData.name);
            setValue("authentication_string", organizationData.authentication_string);
            setValue("pac", organizationData.pac);
            setValue("postcode", organizationData.postcode);
        }
    }, [organizationData, setValue]);

    const onSubmit = async (data) => {
        try {
            setOpen(false);
            await submitData(data); // Ensure submitData is an async function
        } catch (error) {
            displayError("Une erreur de serveur est survenue");
        }
    };

    return (
        <div className="form-modal">
            <div className="custom-modal">
                <span className="close" onClick={() => setOpen(false)}>&times;</span>
                <h1>{title}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="item">
                        <label>Nom :</label>

                        <input
                            {...register("name")}
                            type="text"
                            placeholder="Nom"
                        />
                        {errors.name && <span className="error">{errors.name.message}</span>}
                    </div>

                    <div className="item">
                        <label>Authentification :</label>

                        <input
                            {...register("authentication_string")}
                            type="text"
                            placeholder="Authentification"
                        />
                        {errors.authentication_string &&
                            <span className="error">{errors.authentication_string.message}</span>}
                    </div>

                    <div className="item">
                        <label>PAC :</label>

                        <input
                            {...register("pac")}
                            type="text"
                            placeholder="PAC"
                        />
                        {errors.pac && <span className="error">{errors.pac.message}</span>}
                    </div>

                    <div className="item">
                        <label>Code postal :</label>

                        <input
                            {...register("postcode")}
                            type="text"
                            placeholder="Code Postal"
                        />
                        {errors.postcode && <span className="error">{errors.postcode.message}</span>}
                    </div>

                    <button>{buttonText}</button>
                </form>
            </div>
        </div>
    );
}

export default OrganizationFormModal;
