import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './doughnutChartBox.scss';
import { displayError } from '../../../../../utils/alerts';

/**
 * Displays a chart box with a doughnut chart rendered using Chart.js.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.data - The data to be displayed in the doughnut chart.
 * @param {string} props.id - The unique identifier for the doughnut chart box.
 * @param {string} props.title - The title of the doughnut chart.
 * @param {string} props.label - The label for the doughnut chart.
 * @returns {JSX.Element} - The rendered DoughnutChartBox component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function DoughnutChartBox({ data, id, title, label }) {
    const canvasRef = useRef(null);

    /**
     * Renders the doughnut chart using the provided data.
     *
     * @param {Object} data - The data to be displayed in the doughnut chart.
     * @param {HTMLCanvasElement} canvas - The canvas element representing the doughnut chart.
     * @param {string} label - The label for the doughnut chart.
     *
     * @since 1.0.0
     */
    const renderChart = (data, canvas, label) => {
        if (!canvas) {
            displayError("Canvas of doughnut chart is null.");
            return;
        }

        let backgroundColor, borderColor;

        backgroundColor = [
            'rgba(255, 99, 132, 0.4)',
            'rgba(54, 162, 235, 0.4)',
            'rgba(255, 206, 86, 0.4)',
            'rgba(75, 192, 192, 0.4)',
            'rgba(153, 102, 255, 0.4)',
        ];
        borderColor = [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 206, 86)',
            'rgb(75, 192, 192)',
            'rgb(153, 102, 255)',
        ];

        let ctx = canvas.getContext('2d');

        // Destroy existing chart instance if it exists
        const existingChart = Chart.getChart(ctx);
        if (existingChart) {
            existingChart.destroy();
        }

        // Create a new Chart instance for Doughnut chart
        new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: Object.keys(data),
                datasets: [{
                    label: label,
                    data: Object.values(data),
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    legend: {
                        position: 'right',
                    },
                },
            }
        });
    };

    useEffect(() => {
        if (canvasRef.current && data) {
            renderChart(data, canvasRef.current, label);
        }

        // Clean up existing Chart.js instance when the component unmounts
        return () => {
            if (canvasRef.current) {
                const ctx = canvasRef.current.getContext('2d');
                const chart = Chart.getChart(ctx);
                if (chart) {
                    chart.destroy();
                }
            }
        };
    }, [data, id, title, label]);

    return (
        <canvas id={id} ref={canvasRef}></canvas>
    );
}

export default DoughnutChartBox;
