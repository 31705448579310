import React, {useState, useEffect} from 'react'

import {IMAGES_PATH} from "../../../paths/dirs/dirPaths";
import {USERS_PATH} from "../../../paths/routes/admin/routePaths";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {useParams} from "react-router-dom";
import {fetchEntityData} from "../../../utils/fetch";
import {fields} from "../../../data/user";
import api from "../../../utils/api";
import { useNavigate } from 'react-router-dom';
import SingleUser from "../../../components/single/user/SingleUser";

import './user.scss'

const User = () => {

    let { id } = useParams();
    const [userData, setUserData] = useState({})
    const navigate = useNavigate();


    const updateUser = async (data) => {
        try {
            const response = await api.put(`users/${id}`, data);
            setUserData(response.data.user);
            displaySuccess(response.data.message);
        } catch (e) {
            if (e.response) {
                switch(e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntityData('users', id, fields).then(user => {
            setUserData(user)
        }).catch(error => {
            navigate(USERS_PATH);
            displayError(error.response.data)
        })
    }, []);

    return (
        <div className="user">

            <SingleUser title={userData.name}
                    data={userData}
                    onUpdate={updateUser}

                    /* temporary since user don't have a pic yet */
                    img={userData.img ?? `${IMAGES_PATH}/noavatar.png`}/>
        </div>
    )
}

export default User
