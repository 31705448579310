import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const api = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`,
	withCredentials: true, // This ensures that cookies are sent with cross-origin requests.
});

api.interceptors.request.use(async (config) => {

	const authToken = localStorage.getItem('authToken');
	if (authToken) {
		config.headers.Authorization = `Bearer ${authToken}`;
	}

	const csrfToken = getCookie('XSRF-TOKEN');

	if (csrfToken) {
		config.headers['X-XSRF-TOKEN'] = csrfToken;
	}

	return config;
});

const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop().split(';').shift();
	}
	return null;
}


export default api;
