import React from 'react';

import './indicatorBox.scss';

/**
 * Displays a value box with a title, value, and optional loading spinner.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.api - The API endpoint to fetch data for the stat value.
 * @param {string} props.id - The unique identifier for the stat box.
 * @param {Object} props.filter - Additional filter to be applied to the API request.
 * @returns {JSX.Element} - The rendered IndicatorBox component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function IndicatorBox({data, id}) {

    return (
        <div className="stat-val" id={ id + "-container"}>
           <span id={id + "-val"}>{data}</span>
        </div>
    )
}

export default IndicatorBox;