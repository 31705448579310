import React, { useEffect, useState, useRef } from 'react';
import api from '../../../../utils/api';
import BarChartBox from '../statBoxTypes/barChartBox/BarChartBox';
import DoughnutChartBox from '../statBoxTypes/doughnutChartBox/DoughnutChartBox';
import IndicatorBox from '../statBoxTypes/indicatorBox/IndicatorBox';
import { displayError } from '../../../../utils/alerts';

import { FaInfo } from 'react-icons/fa';

import './statBox.scss';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles

/**
 * Displays a stat box with various chart types and value display.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.activeType - The currently active chart type.
 * @param {Object} props.compatibleChartTypes - A map of compatible chart types.
 * @param {string} props.api - API endpoint for fetching data.
 * @param {string} props.name - Unique identifier for the stat box.
 * @param {Object} props.filters - Filters to be applied when fetching data.
 * @param {string} props.label - Label for the stat box.
 * @param {ReactNode} props.children - Child components.
 * @returns {JSX.Element} - The rendered StatBox component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
const StatBox = ({ activeType, compatibleChartTypes, apiEndpoint, name, filters, label, children }) => {

  const [activeChartType, setActiveChartType] = useState(null);
  const [data, setData] = useState(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [isChartTypeMenuVisible, setIsChartTypeMenuVisible] = useState(false);

  // Ref for context menu
  const contextMenuRef = useRef(null);

  // Set active chart type when it changes
  useEffect(() => {
    setActiveChartType(activeType);
  }, [activeType, compatibleChartTypes]);

  // Fetch data when API or filter change
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(filters);
        const response = await api.get(`/${apiEndpoint}?${queryParams}`);
        setData(response.data.data);
      } catch (error) {
        displayError(`Error fetching data: ${error.message}`);
      }
    };

    fetchData();
  }, [apiEndpoint, filters]);

  // Handle context menu click for changing chart type
  const handleContextMenuClick = (clickedChartType) => {
    setActiveChartType(clickedChartType);
    setIsContextMenuVisible(false);
    setIsChartTypeMenuVisible(false);
  };

  // Toggle visibility of context menu
  const toggleContextMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const buttonPosition = event.currentTarget.getBoundingClientRect();
    setContextMenuPosition({
      top: buttonPosition.bottom + 10 + window.scrollY + 'px',
      left: buttonPosition.left - 60 + window.scrollX + 'px',
    });
    setIsContextMenuVisible(!isContextMenuVisible);
    setIsChartTypeMenuVisible(false);
  };

  // Close context menu
  const closeContextMenu = () => {
    setIsContextMenuVisible(false);
  };

  // Close context menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target) && isContextMenuVisible) {
        closeContextMenu();
      }
    };

    window.addEventListener('click', handleClickOutside);

    // Cleanup click event listener on unmount
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isContextMenuVisible]);

  // Render the context menu based on visibility
  const renderContextMenu = () => {
    if (isChartTypeMenuVisible) {
      return (
        <div ref={contextMenuRef} className="context-menu" style={contextMenuPosition} onClick={(e) => e.stopPropagation()}>
          <div className="context-menu-content">
            <ul className="list-group list-group-flush">
              {renderChartTypeOptions()}
            </ul>
          </div>
        </div>
      );
    }

    return (
      <div ref={contextMenuRef} className="context-menu" style={contextMenuPosition} onClick={(e) => e.stopPropagation()}>
        <div className="context-menu-content">
          <ul className="list-group list-group-flush">
            <li className="list-group-item" onClick={() => handleToggleChartTypeMenu()}>
              Changer Graphe
            </li>
          </ul>
        </div>
      </div>
    );
  };

  // Toggle visibility of chart type menu
  const handleToggleChartTypeMenu = () => {
    setIsChartTypeMenuVisible(!isChartTypeMenuVisible);
  };

  // Render chart type options
  const renderChartTypeOptions = () => {
    return (
      <>
        {Object.entries(compatibleChartTypes).map(([chartTypeName, chartTypeDisplayableName]) => (
          <li
            key={chartTypeName}
            className="list-group-item"
            onClick={() => handleContextMenuClick(chartTypeName)}
          >
            {chartTypeDisplayableName}
          </li>
        ))}
      </>
    );
  };

  // Determine the chart component based on the active chart type
  let chartComponent;

  switch (activeChartType) {
    case 'bar-chart':
      chartComponent = <BarChartBox data={data} id={name} label={label} />;
      break;
    case 'doughnut-chart':
      chartComponent = <DoughnutChartBox data={data} id={name} label={label} />;
      break;
    case 'indicator':
      if (typeof data === 'number') {
        chartComponent = <IndicatorBox data={data} id={name} />;
      }
      break;
    default:
      chartComponent = null;
  }

  return (
    <div className="chart-box">

      {/* Stat Header */}
      <div className="stat-header">
        <div className="row justify-content-between align-items-center">
          <div className="col-8">
            <div className="stat-title">
              <h5>{children}</h5>
            </div>
          </div>
          <div className="col-4 text-right stat-header-buttons-container">
            <div className="float-end">
              <div className="row">

                {/* Info Button */}
                <div className="col-6">
                  <button className="stat-header-button">
                    <div className="info-icon-background">
                      <FaInfo className="info-icon" />
                    </div>
                  </button>
                </div>

                {/* Context Menu Button */}
                <div className="col-6 d-flex justify-content-end stat-header-context-menu-button-container">
                  <button
                    className="stat-header-button"
                    onClick={(event) => toggleContextMenu(event)}
                  >
                    <span className="statbox-three-dots">&#8942;</span>
                  </button>
                  {isContextMenuVisible && renderContextMenu()}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Stat Body */}
      <div className="stat-body">
        { chartComponent }
      </div>
    </div>
  );
};

export default StatBox;
