import React from 'react';

/**
 * Displays a dropdown filter based on the provided filterOption data.
 *
 * @component
 * @param {Object} filterOption - The filter option data.
 * @param {string} filterOption.name - The unique identifier for the filter option.
 * @param {string} filterOption.displayable_name - The displayable name for the filter option.
 * @param {Array} filterOption.options - The array of options for the dropdown.
 * @returns {JSX.Element} - The rendered DropdownFilter component.
 *
 * @since 1.0.0
 */
const DropdownFilter = ({ filterOption }) => (
  <div className="col-lg-3 col-6 mb-3">
    <select
      id={`filter-option-${filterOption.name}`}
      name={`filter-option-${filterOption.name}`}
      className="form-control"
    >
      <option value="" disabled selected>{filterOption.displayable_name}</option>
      {filterOption.options && filterOption.options.map((option, optionIndex) => (
        <option key={optionIndex} value={option.shortName}>{option.name}</option>
      ))}
    </select>
  </div>
);

export default DropdownFilter;
