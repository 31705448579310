import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthProvider';
import Loading from "../components/loading/Loading";

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  const { authUser, isLoading } = useAuth();

  if (isLoading) {
    return <Loading/>;
  }

  if (!authUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;