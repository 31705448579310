import api from "./api";
import {filterObject} from "./objects";

/**
 * Fetch specific fields of an entity.
 * @param {string} slug - The base api endpoint of the entity fetching.
 * @param {string} id - The id of the entity.
 * @param {string[]} fields - The fields that we want to keep.
 * @returns {*} The entity with the filtered fields.
 *
 * @since 1.0.0
 */
const fetchEntityData = async (slug, id, fields) => {
    try {
        const response = await api.get(`/${slug}/${id}`);

        return fields ? filterObject(response.data, fields) : response.data

    } catch (error) {
        throw error
    }
};

/**
 * Fetch specific fields of many entities.
 * @param {string} slug - The base api endpoint of the entity fetching.
 * @param {string[]} fields - The fields that we want to keep.
 * @returns {*} The entities with the filtered fields.
 *
 * @since 1.0.0
 */
const fetchEntitiesData = async (slug, fields) => {
    try {

        const response = await api.get(`/${slug}`);

        return fields ? response.data.map(object => filterObject(object, fields)) : response.data;

    } catch (error) {
        throw error;
    }
};

export {fetchEntityData, fetchEntitiesData};