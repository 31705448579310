import React, { useEffect, useState } from 'react';
import { displayError } from "../../../utils/alerts";
import Loading from "../../loading/Loading";
import api from "../../../utils/api";
import $ from 'jquery';
import 'jstree';
import 'jstree/dist/themes/default/style.min.css';
import './rolesTree.scss';

const RolesTree = ({ onRoleClick, rolesTreeData }) => {

    useEffect(() => {

        $('#js-tree').jstree({
            core: {
                data: rolesTreeData,
            },
        });

        $('#js-tree').on('select_node.jstree', function (e, data) {
            if (typeof onRoleClick === 'function') {
                onRoleClick(data.node.id);
            }
        });

        // Cleanup event handlers on component unmount
        return () => {
            $('#js-tree').jstree('destroy').off('select_node.jstree');
        };
    }, [onRoleClick, rolesTreeData]);

    return (
        <div className="roles-tree">
            <div id="js-tree"></div>
        </div>
    );
};

export default RolesTree;