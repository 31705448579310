import {
    HOME_PATH,
    PROFILE_PATH,
    USERS_PATH,
    ORGANIZATIONS_PATH,
    ORGANIZATION_UNITS_PATH,
    CITIES_PATH,
    SITES_PATH,
    ACTION_TYPES_PATH,
    ROLES_PATH
} from "../paths/routes/admin/routePaths";
import {IMAGES_PATH} from "../paths/dirs/dirPaths";

export const menu = [
    {
        id: 1,
        title: 'principal',
        listItems: [
            {
                id: 1,
                title: 'Page d\'accueil',
                url: `${HOME_PATH}`,
                icon: `${IMAGES_PATH}/home`,
            },
            {
                id: 2,
                title: 'Profile',
                url: `${PROFILE_PATH}`,
                icon: `${IMAGES_PATH}/profile`,
            },
        ]
    },
    {
        id: 2,
        title: 'liste',
        listItems: [
            {
                id: 1,
                title: 'Utilisateurs',
                url: `${USERS_PATH}`,
                icon: `${IMAGES_PATH}/user`,
            },
            {
                id: 2,
                title: 'Structures',
                url: `${ORGANIZATIONS_PATH}`,
                icon: `${IMAGES_PATH}/organization`,
            },
            {
                id: 3,
                title: 'Unités',
                url: `${ORGANIZATION_UNITS_PATH}`,
                icon: `${IMAGES_PATH}/units`,
            },
            {
                id: 4,
                title: 'Villes',
                url: `${CITIES_PATH}`,
                icon: `${IMAGES_PATH}/city`,
            },
            {
                id: 5,
                title: 'Sites',
                url: `${SITES_PATH}`,
                icon: `${IMAGES_PATH}/site`,
            },
            {
                id: 6,
                title: 'Type d\'Actions',
                url: `${ACTION_TYPES_PATH}`,
                icon: `${IMAGES_PATH}/action-type`,
            },
            {
                id: 7,
                title: 'Roles',
                url: `${ROLES_PATH}`,
                icon: `${IMAGES_PATH}/roles`,
            },
        ]
    }
]