import React, {useState} from "react";
import '../single.scss'
import OrganizationFormModal from "../../modal/formModal/organization/OrganizationFormModal";

const SingleOrganization = ({onUpdate, title, data, img}) => {

    const [open, setOpen] = useState(false)

    const updateEntity = (data) => {
        onUpdate(data, setOpen)
    }

    return <div className="single">
        <div className="view">
            <div className="info">
                <div className="topInfo">
                    {img && <img src={img} alt=""/>}
                    <h1>{title}</h1>
                    <button onClick={() => setOpen(true)}>Modifier</button>
                </div>
                <div className="details">

                    <div className="item">
                        <span className="itemTitle">Id :</span>
                        <span className="itemValue">{data['id'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Nom :</span>
                        <span className="itemValue">{data['name'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Authentification :</span>
                        <span className="itemValue">{data['authentication_string'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">PAC :</span>
                        <span className="itemValue">{data['pac'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Code Postal :</span>
                        <span className="itemValue">{data['postcode'] ?? 'N/A'}</span>
                    </div>

                </div>
            </div>
            <hr/>
            <div className="chart">

            </div>
        </div>
        <div className="activities">

        </div>

        { open && <OrganizationFormModal setOpen={setOpen}
                                 organizationData={data}
                                 submitData={updateEntity}
                                 title="Modifier Structure"
                                 buttonText="Modifier"/>
        }
    </div>
}

export default SingleOrganization;
