import React, {useEffect, useState} from 'react'
import "./users.scss"
import {DataTable} from "../../../components/dataTable/DataTable";
import {IMAGES_PATH} from "../../../paths/dirs/dirPaths";
import {fetchEntitiesData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/user";
import api from "../../../utils/api";
import UserFormModal from "../../../components/modal/formModal/user/UserFormModal";
import Loading from "../../../components/loading/Loading";


const columns = [
    { id: 1, type: "number", field: "id", headerName: "ID", width: 90 },
    {
        id: 2,
        field: "img",
        type: "file",
        headerName: "Profile",
        width:100,
        renderCell: (params) => {
            return <img src={params.row.img || `${IMAGES_PATH}/noavatar.png`} alt=""/>
        }
    },
    {
        id: 3,
        field: 'name',
        type: "text",
        headerName: 'Nom',
        width: 150,
    },
    {
        id: 4,
        field: 'email',
        type: "email",
        headerName: 'Email',
        width: 200,
    }
];

export const Users = () => {
    const [open, setOpen] = useState(false)
    const [usersData, setUsersData] = useState([])
    const [loading, setLoading] = useState(false)

    const addUser = async (data) => {
        try {
            setLoading(true);
            const response = await api.post('users', data);
            setUsersData((prevUsersData) => [...prevUsersData, response.data.user]);
            setLoading(false);
            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };

    const deleteUser = async (id) => {
        try {
            setLoading(true);

            const response = await api.delete(`users/${id}`);

            if (response.data.success) {
                setUsersData(prevUsers => prevUsers.filter(user => user.id !== id));
            }

            setLoading(false);
            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);

            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntitiesData('users', fields).then(users => {
            setUsersData(users)
        }).catch(error => displayError(error.response))
    }, []);

    if (loading) {
        return <Loading/>
    }

    return (
        <div className="users">
            <div className="info">
                <h1>Utilisateurs</h1>
                <button onClick={()=>setOpen(true)}>Ajouter Utilisateur</button>
            </div>
            <DataTable onDelete={deleteUser} slug="users" rows={usersData} columns={columns}/>
            {open && <UserFormModal title="Ajouter Utilisateur"
                                    buttonText="Ajouter"
                                    setOpen={setOpen}
                                    submitData={addUser}/>
            }
        </div>
    )
}

export default Users