import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {fetchEntityData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/organizationUnit";
import api from "../../../utils/api";
import {ORGANIZATION_UNITS_PATH} from "../../../paths/routes/admin/routePaths";
import { useNavigate } from 'react-router-dom'
import SingleOrganizationUnit from "../../../components/single/organizationUnit/SingleOrganizationUnit";

import './organizationUnit.scss';

const OrganizationUnit = () => {

    let { id } = useParams();
    const [organizationUnitData, setOrganizationUnitData] = useState({})
    const navigate = useNavigate()

    const updateOrganizationUnit = async (data) => {
        try {
            const { organization: { id: organizationId }, ...otherUnitsData } = organizationUnitData;
            const dataToSend = { ...otherUnitsData, 'organization_id': organizationId, ...data };
            const response = await api.put(`organization-units/${id}`, dataToSend);
            setOrganizationUnitData(response.data.organization_unit);
            displaySuccess(response.data.message);
        } catch (e) {
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntityData('organization-units', id, fields).then(organizationUnit => {
            setOrganizationUnitData(organizationUnit)
        }).catch(error => {
            navigate(ORGANIZATION_UNITS_PATH);
            displayError(error.response.data)
        })
    }, []);

    return (
        <div className="organization-unit"><SingleOrganizationUnit title={organizationUnitData.name}
                                                   onUpdate={updateOrganizationUnit}
                                                   data={organizationUnitData}/></div>
    )
}
export default OrganizationUnit
