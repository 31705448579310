import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { fetchEntitiesData } from "../../../../utils/fetch";
import { displayError } from "../../../../utils/alerts";
import Loading from "../../../loading/Loading";
import { zodResolver } from '@hookform/resolvers/zod';
import { organizationUnitFormSchema } from './organizationUnitFormSchema';

import '../formModal.scss';


export const OrganizationUnitFormModal = ({ title, buttonText, setOpen, organizationUnitData, submitData }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(organizationUnitFormSchema),
    });
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetches all the organizations
    useEffect(() => {
        fetchEntitiesData('organizations', ['id', 'name'])
            .then((data) => {
                setOrganizations(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                displayError("Erreur de serveur");
            });
    }, []);

    // Populate form data if organizationUnitData exists (for edit mode)
    useEffect(() => {
        if (organizationUnitData) {
            setValue("name", organizationUnitData.name);
            setValue("shortname", organizationUnitData.shortname);
            setValue("organization_id", organizationUnitData.organization.id);
        }
    }, [organizationUnitData, setValue, organizations]);

    const onSubmit = async (data) => {
        try {
            setOpen(false);
            await submitData(data); // Ensure submitData is an async function
        } catch (error) {
            displayError("Une erreur de serveur est survenue");
        }
    };

    if (loading) {
        return <Loading/>;
    }

    return (
        <div className="form-modal">
            <div className="custom-modal">
                <span className="close" onClick={() => setOpen(false)}>&times;</span>
                <h1>{title}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="item">
                        <label>Nom :</label>

                        <input
                            {...register("name")}
                            type="text"
                            placeholder="Nom"
                        />
                        {errors.name && <span className="error-message">{errors.name.message}</span>}
                    </div>

                    <div className="item">
                        <label>Abbrév. :</label>

                        <input
                            {...register("shortname")}
                            type="text"
                            placeholder="Abbrév."
                        />
                        {errors.shortname && <span className="error-message">{errors.shortname.message}</span>}
                    </div>

                    <div className="item">
                        <label>Structure :</label>

                        <select {...register("organization_id")}>
                            <option value="" selected={!organizationUnitData}>Structure</option>
                            {organizations.map((org) => (
                                <option key={org.id} value={org.id}>{org.name}</option>
                            ))}
                        </select>
                        {errors.organization_id &&
                            <span className="error-message">{errors.organization_id.message}</span>}
                    </div>

                    <button type="submit">{buttonText}</button>
                </form>
            </div>
        </div>
    );
}

export default OrganizationUnitFormModal;
