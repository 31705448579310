import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {fetchEntityData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/actionType";
import api from "../../../utils/api";
import {ACTION_TYPES_PATH} from "../../../paths/routes/admin/routePaths";
import { useNavigate } from 'react-router-dom'
import SingleActionType from "../../../components/single/actionType/SingleActionType";

import './actionType.scss';

const ActionType = () => {

    let { id } = useParams();
    const [actionTypeData, setActionTypeData] = useState({})
    const navigate = useNavigate()

    const updateActionType = async (data) => {
        try {
            const { organization_unit: { id: organizationUnitId }, ...otherUnitsData } = actionTypeData;
            const dataToSend = { ...otherUnitsData, 'organization_unit_id': organizationUnitId, ...data };
            const response = await api.put(`action-types/${id}`, dataToSend);
            setActionTypeData(response.data.action_type);
            displaySuccess(response.data.message);
        } catch (e) {
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntityData('action-types', id, fields).then(actionType => {
            setActionTypeData(actionType)
        }).catch(error => {
            navigate(ACTION_TYPES_PATH);
            displayError(error.response.data)
        })
    }, []);

    return (
        <div className="action-type"><SingleActionType title={actionTypeData.name}
                                                                   onUpdate={updateActionType}
                                                                   data={actionTypeData}/></div>
    )
}
export default ActionType
