import React from 'react';

import './app.scss';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import GuestRoute from "./routes/GuestRoute";
import Login from "./pages/auth/login/Login";
import ProtectedRoute from "./routes/ProtectedRoute";
import Home from "./pages/admin/home/Home";
import MainLayout from "./layouts/main/MainLayout";
import AdminLayout from "./layouts/admin/AdminLayout";
import Profile from "./pages/admin/profile/Profile";
import OrganizationUnits from "./pages/admin/orgnizationUnits/OrganizationUnits";
import Organizations from "./pages/admin/organizations/Organizations";
import Users from "./pages/admin/users/Users";
import User from "./pages/admin/user/User";
import Organization from "./pages/admin/organization/Organization";
import OrganizationUnit from "./pages/admin/organizationUnit/OrganizationUnit";
import Landing from "./pages/stat/landing/Landing";
import Cities from "./pages/admin/cities/Cities";
import Sites from "./pages/admin/sites/Sites";
import ActionTypes from "./pages/admin/actionTypes/ActionTypes";
import City from "./pages/admin/city/City";
import Site from "./pages/admin/site/Site";
import ActionType from "./pages/admin/actionType/ActionType";
import Roles from "./pages/admin/roles/Roles";

const App = () => {

	return (
		<div>
			<Router>
				<Routes>
					<Route element={<MainLayout/>}>

						{/* Main Routes */}
						<Route path='login' element={
							<GuestRoute>
								<Login />
							</GuestRoute>
						} />

						<Route path='' element={
							<ProtectedRoute>
								<Landing />
							</ProtectedRoute>
						} />

					</Route>

					<Route element={<AdminLayout/>}>

						{/* Admin Routes */}
						<Route path='admin' element={
							<ProtectedRoute>
								<Home />
							</ProtectedRoute>
						} />

						<Route path='admin/profile' element={
							<ProtectedRoute>
								<Profile />
							</ProtectedRoute>
						} />

						<Route path='admin/users' element={
							<ProtectedRoute>
								<Users />
							</ProtectedRoute>
						} />

						<Route path='admin/users/:id' element={
							<ProtectedRoute>
								<User />
							</ProtectedRoute>
						} />

						<Route path='admin/organizations' element={
							<ProtectedRoute>
								<Organizations />
							</ProtectedRoute>
						} />

						<Route path='admin/organizations/:id' element={
							<ProtectedRoute>
								<Organization />
							</ProtectedRoute>
						} />

						<Route path='admin/organization-units' element={
							<ProtectedRoute>
								<OrganizationUnits />
							</ProtectedRoute>
						} />

						<Route path='admin/organization-units/:id' element={
							<ProtectedRoute>
								<OrganizationUnit />
							</ProtectedRoute>
						} />

						<Route path='admin/cities' element={
							<ProtectedRoute>
								<Cities />
							</ProtectedRoute>
						} />

						<Route path='admin/cities/:id' element={
							<ProtectedRoute>
								<City />
							</ProtectedRoute>
						} />

						<Route path='admin/sites' element={
							<ProtectedRoute>
								<Sites />
							</ProtectedRoute>
						} />

						<Route path='admin/sites/:id' element={
							<ProtectedRoute>
								<Site />
							</ProtectedRoute>
						} />

						<Route path='admin/action-types' element={
							<ProtectedRoute>
								<ActionTypes />
							</ProtectedRoute>
						} />

						<Route path='admin/action-types/:id' element={
							<ProtectedRoute>
								<ActionType />
							</ProtectedRoute>
						} />

						<Route path='admin/roles' element={
							<ProtectedRoute>
								<Roles />
							</ProtectedRoute>
						} />

					</Route>
				</Routes>
			</Router>

		</div>
	);
};

export default App;