import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { displayError } from "../../../../utils/alerts";
import { zodResolver } from '@hookform/resolvers/zod';
import { siteFormSchema } from './siteFormSchema';
import {fetchEntitiesData} from "../../../../utils/fetch";
import Loading from "../../../loading/Loading";

import '../formModal.scss';

export const SiteFormModal = ({ title, buttonText, setOpen, siteData, submitData }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(siteFormSchema),
    });
    const [ cities, setCities ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    // Fetches all the organization units
    useEffect(() => {
        fetchEntitiesData('cities', ['id', 'name'])
            .then((data) => {
                setCities(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                displayError("Erreur de serveur");
            });
    }, []);

    // Populate form data if siteData exists (for edit mode)
    useEffect(() => {
        if (siteData) {
            setValue("name", siteData.name);
            setValue("city_id", siteData.city.id);
            setValue("preference_index", siteData.preference_index);
            setValue("priority", siteData.priority);
            setValue("status", siteData.status);}
    }, [siteData, setValue]);

    const onSubmit = async (data) => {
        try {
            setOpen(false);
            await submitData(data);
        } catch (error) {
            displayError("Une erreur de serveur est survenue");
        }
    };

    if (loading) {
        return <Loading/>
    }

    return (
        <div className="form-modal">
            <div className="custom-modal">
                <span className="close" onClick={() => setOpen(false)}>&times;</span>
                <h1>{title}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="item">
                        <label>Nom :</label>

                        <input
                            {...register("name")}
                            type="text"
                            placeholder="Nom"
                        />
                        {errors.name && <span className="error">{errors.name.message}</span>}
                    </div>

                    <div className="item">
                        <label>Indice de préférence :</label>

                        <input
                            {...register("preference_index")}
                            type="number"
                            placeholder="preference"
                        />
                        {errors.preference_index && <span className="error">{errors.preference_index.message}</span>}
                    </div>

                    <div className="item">
                        <label>Priorité :</label>

                        <input
                            {...register("priority")}
                            type="number"
                            placeholder="priorité"
                        />
                        {errors.priority && <span className="error">{errors.priority.message}</span>}
                    </div>

                    <div className="item">
                        <label>Status :</label>

                        <input
                            {...register("status")}
                            type="number"
                            placeholder="status"
                        />
                        {errors.status && <span className="error">{errors.status.message}</span>}
                    </div>

                    <div className="item">
                        <label>Ville :</label>

                        <select {...register("city_id")}>
                            <option value="" selected={!siteData}>Ville</option>
                            {cities.map((city) => (
                                <option key={city.id} value={city.id}>{city.name}</option>
                            ))}
                        </select>
                        {errors.city_id &&
                            <span className="error-message">{errors.city_id.message}</span>}
                    </div>

                    <button>{buttonText}</button>
                </form>
            </div>
        </div>
    );
}

export default SiteFormModal;
