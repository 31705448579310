import React, {useState} from 'react';
import './roleDetails.scss';
import RoleFormModal from "../../modal/formModal/role/RoleFormModal";

const RoleDetails = ({ role, addRole, deleteRole }) => {

    const [openAddModal, setOpenAddModal] = useState(false)

    if (!role) {
        return (
            <div className="role-details">
                <p className="no-role-selected">Pas de rôle sélectionné</p>
            </div>
        );
    }

    return (
        <div className="role-details">
            <div className="role-details-header">
                <h2 className="role-name">{role.name}</h2>

                <div className="role-details-actions">
                    <button className="add-role-button" onClick={() => setOpenAddModal(!openAddModal)}>Ajouter sous-role</button>
                    <button className="delete-role-button" onClick={() => deleteRole(role.id)}>Supprimer role</button>
                </div>
            </div>

            <div className="role-info">
                <h3>Informations générales</h3>
                <p><strong>Organisation:</strong> {role.organization ? role.organization.name : 'Non spécifiée'}</p>
            </div>

            <div className="role-permissions">
                <h3>Permissions</h3>
                {role.permissions && role.permissions.length > 0 ? (
                    <div className="permissions-grid">
                        {role.permissions.map(permission => (
                            <div key={permission.id}>{permission.name}</div>
                        ))}
                    </div>
                ) : (
                    <p>Aucune permission attribuée</p>
                )}
            </div>

            <div className="role-users">
                <h3>Utilisateurs associés</h3>
                {role.users && role.users.length > 0 ? (
                    <ul>
                        {role.users.map(user => (
                            <li key={user.id}>{user.name}</li>
                        ))}
                    </ul>
                ) : (
                    <p>Aucun utilisateur associé</p>
                )}
            </div>

            {
                openAddModal && <RoleFormModal setOpen={setOpenAddModal}
                                               submitData={addRole}
                                               title="Ajouter Role"
                                               buttonText="Ajouter"/>
            }
        </div>

    );
};

export default RoleDetails;