import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import Lightpick from 'lightpick';
import 'lightpick/css/lightpick.css';

import './filterBar.scss';
import { displayError } from '../../../utils/alerts';
import FilterOption from '../filterOption/FilterOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

/**
 * Displays a filter bar with search, date and dynamically rendered filter options.
 *
 * @component
 * @returns {JSX.Element} - The rendered Filter component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function FilterBar({ onFilterChange, onSearchChange }) {

    const [filterOptionsProperties, setFilterOptionsProperties] = useState([]);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 991);

  // Fetch initial data and set up date picker on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await api.get(`/filterOptionComponentsProperties`);
        setFilterOptionsProperties(response.data);
      } catch (error) {
        displayError('Error fetching data: ' + error.message);
      }
    };

    fetchInitialData();

    const picker = new Lightpick({
      field: document.getElementById('startRange'),
      secondField: document.getElementById('endRange'),
      singleDate: false,
      lang: 'fr',
    });

    // Cleanup lightpick instance on unmount
    return () => {
      picker.destroy();
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup resize event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Fetch options data whenever filterOptionsProperties change
  useEffect(() => {
    const fetchOptionsData = async () => {
      try {
        const updatedOptions = await Promise.all(
          filterOptionsProperties.map(async (filterOption) => {
            if (filterOption.api) {
              try {
                const response = await api.get(`${filterOption.api.endpoint}`);
                return {
                  ...filterOption,
                  options: response.data,
                };
              } catch (error) {
                displayError(`Error fetching options data for ${filterOption.name}: ${error.message}`);
                return filterOption;
              }
            }
            return filterOption;
          })
        );

        setFilterOptionsProperties((prevOptions) => {
          const areOptionsDifferent = JSON.stringify(updatedOptions) !== JSON.stringify(prevOptions);
          return areOptionsDifferent ? updatedOptions : prevOptions;
        });
      } catch (error) {
        displayError(`Error fetching options data: ${error}`);
      }
    };

    fetchOptionsData();
  }, [filterOptionsProperties]);


  // Save selected filter
  const handleSaveFilters = () => {
    const selectedDropdownFilters = {};

    if (showMoreFilters) {
      filterOptionsProperties.forEach((filterOption) => {
        if (filterOption.type.name === 'dropdown') {
          const dropdown = document.getElementById(`filter-option-${filterOption.name}`);
          selectedDropdownFilters[filterOption.name] = dropdown.value;
        }
      });
    }

    const startRange = document.getElementById('startRange').value;
    const endRange = document.getElementById('endRange').value;

    const filters = {
      ...selectedDropdownFilters,
      startRange,
      endRange,
    };

    onFilterChange(filters);
  };

  // Delete selected filter
const handleDeleteFilters = () => {
  const selectedDropdownFilters = {};

  if (showMoreFilters) {
      filterOptionsProperties.forEach((filterOption) => {
          if (filterOption.type.name === 'dropdown') {
              const dropdown = document.getElementById(`filter-option-${filterOption.name}`);
              dropdown.selectedIndex = 0;  // Set the dropdown to its default option
              selectedDropdownFilters[filterOption.name] = ''; 
          }
      });
  }

  document.getElementById('startRange').value = '';
  document.getElementById('endRange').value = '';

  const filters = {
      ...selectedDropdownFilters,
      startRange: '',
      endRange: '',
  };

  onFilterChange(filters);
};


  /**
   * Renders filter components based on the retrieved data.
   *
   * @function
   * @returns {JSX.Element[]} - Array of JSX elements representing filter components.
   */
  const renderFilterOptions = () => {
    const filteredOptions = showMoreFilters ? filterOptionsProperties : [];
    return filteredOptions.map((filterOption, index) => (
      <FilterOption key={index} filterOption={filterOption} />
    ));
  };

  /**
   * Renders static filter options for search and date range.
   *
   * @function
   * @returns {JSX.Element} - The rendered static filter options.
   *
   * @since 1.0.0
  */
  const renderStaticFilterOptions = () => {
    return (
		<>
			{/* Search Input */}
			<div className="col-lg-6 col-12 mb-lg-0 mb-3">
				<input
					type="text"
					placeholder="Rechercher..."
					id="searchStatName"
					name="searchStatName"
					className="form-control"
					onChange={handleSearchChange}
					autoComplete="off"
				/>
			</div>
				
			{/* Start Range Input */}
			<div className="col-lg-3 col-6 mb-lg-0 mb-3">
				<input 
					type="text" 
					placeholder="Debut" 
					id="startRange"
					className="form-control"
					autoComplete="off" 
				/>
			</div>
				
			{/* End Range Input */}
			<div className="col-lg-3 col-6">
				<input 
					type="text"
					placeholder="Fin"
					id="endRange"
					className="form-control" 
					autoComplete="off"	
				/>
			</div>

		</>
    )
  }

  // Handle search input change
  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    onSearchChange(searchValue);
  };

  // Toggle more filter visibility
  const handleToggleMoreFilters = () => {
    setShowMoreFilters(!showMoreFilters);
  };

  return (
    <>
		<div className={`filter-box mt-5 ${showMoreFilters ? 'more-filter-open' : ''}`}>

			{/* Filter Header */}
			<div className="filter-header">
				<div className="row w-100 d-flex justify-content-end">
					<div className="col-lg-2 col-6">
						<button className="btn btn-primary w-100" onClick={handleSaveFilters}>
							{isSmallScreen ? 'Enregistrer' : 'Enregistrer les filtres'}
						</button>
					</div>
					<div className="col-lg-2 col-6">
						<button className="btn btn-secondary w-100" onClick={handleDeleteFilters}>
							{isSmallScreen ? 'Supprimer' : 'Supprimer les filtres'}
						</button>
					</div>
				</div>
			</div>

			{/* Filter Body */}
			<div className="filter-body mt-3">
				<div className="row">
					{ renderFilterOptions()}
				</div>
				<div className="row">
				
					{ renderStaticFilterOptions() }
				
					{/* Toggle More Filters Button */}
					<div className="d-flex justify-content-center">
						<div className={`curtain-logo ${showMoreFilters ? 'open' : ''}`} 
						onClick={handleToggleMoreFilters}>
							<FontAwesomeIcon icon={showMoreFilters ? faTimes : faBars} />
						</div>
					</div>

				</div>
			</div>

		</div>
    </>
  );
}

export default FilterBar;
