import React from 'react';
import NavbarItems from '../navbarItems/NavbarItems';

import './navbar.scss';

/**
 * The navigation bar component that includes a logo, toggler, and navigation links.
 *
 * @component
 * @returns {JSX.Element} - The rendered Navbar component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function Navbar() {
    return (
        <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
            <div className="container">

                { /** Navbar Logo */ }
                <a href="/public">
                    <img alt="A Vos Soins Extranet" className="navbar-logo" src="/images/avossoins-logo.png"/>
                </a>

                { /** Navbar Toggler */ }
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <NavbarItems/>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
