import React, {useEffect, useState} from 'react';
import {DataTable} from "../../../components/dataTable/DataTable";
import {fetchEntitiesData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/organizationUnit";
import api from "../../../utils/api";
import OrganizationUnitFormModal from "../../../components/modal/formModal/organizationUnit/OrganizationUnitFormModal";

import './organizationUnits.scss';
import Loading from "../../../components/loading/Loading";

const columns = [
    { id: 1, type: "number", field: "id", headerName: "ID", width: 90 },
    {
        id: 2,
        field: 'name',
        type: 'text',
        headerName: 'Nom',
        width: 150,
    },
    {
        id: 3,
        field: 'shortname',
        type: 'text',
        headerName: 'Abbrév.',
        width: 150,
    },
    {
        id: 4,
        field: 'organization',
        type: 'text',
        headerName: 'Structure',
        width: 150,
        renderCell: (params) => {
            return params.row.organization ? params.row.organization.name : 'N/A';
        }
    }
];

export const OrganizationUnits = () => {
    const [open, setOpen] = useState(false)
    const [organizationUnitsData, setOrganizationUnitsData] = useState([])
    const [loading, setLoading] = useState(false);

    const addOrganizationUnit = async (data) => {
        try {
            setLoading(true);
            const response = await api.post('organization-units', data);
            setOrganizationUnitsData(prevData => [...prevData, response.data.organization_unit]);

            setLoading(false);
            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);

            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    const deleteOrganizationUnit = async (id) => {
        try {
            setLoading(true);

            const response = await api.delete(`organization-units/${id}`);

            if (response.data.success) {
                setOrganizationUnitsData(prevData => prevData.filter(unit => unit.id !== id));
            }

            setLoading(false);
            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);

            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntitiesData('organization-units', fields).then(organizationUnits => {
            setOrganizationUnitsData(organizationUnits)
        }).catch(error => displayError(error.response))
    }, []);

    if (loading) {
        return <Loading/>
    }

    return (
        <div className="organization-units">
            <div className="info">
                <h1>Unités</h1>
                <button onClick={()=>setOpen(true)}>Ajouter Unité</button>
            </div>
            <DataTable onDelete={deleteOrganizationUnit} slug="organization-units" rows={organizationUnitsData} columns={columns}/>

            { open && <OrganizationUnitFormModal setOpen={setOpen}
                                             submitData={addOrganizationUnit}
                                             title="Ajouter Unité"
                                             buttonText="Unité"/>
            }
        </div>
    )
}

export default OrganizationUnits
