import React, {useEffect, useState} from 'react';
import { useForm } from "react-hook-form";
import { displayError } from "../../../../utils/alerts";
import { zodResolver } from '@hookform/resolvers/zod';
import { actionTypeFormSchema } from './actionTypeFormSchema';

import '../formModal.scss';
import {fetchEntitiesData} from "../../../../utils/fetch";
import Loading from "../../../loading/Loading";

export const ActionTypeFormModal = ({ title, buttonText, setOpen, actionTypeData, submitData }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(actionTypeFormSchema),
    });

    const [organizationUnits, setOrganizationUnits] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetches all the organization units
    useEffect(() => {
        fetchEntitiesData('organization-units', ['id', 'name'])
            .then((data) => {
                setOrganizationUnits(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                displayError("Erreur de serveur");
            });
    }, []);

    // Populate form data if actionTypeData exists (for edit mode)
    useEffect(() => {

        if (actionTypeData) {
            setValue("name", actionTypeData.name);
            setValue("organization_unit_id", actionTypeData.organization_unit.id);
            setValue("preference_index", actionTypeData.preference_index);
            setValue("status", actionTypeData.status);

        }
    }, [actionTypeData, setValue]);

    const onSubmit = async (data) => {
        try {
            setOpen(false);
            await submitData(data);
        } catch (error) {
            displayError("Une erreur de serveur est survenue");
        }
    };

    if (loading) {
        return <Loading/>;
    }

    return (
        <div className="form-modal">
            <div className="custom-modal">
                <span className="close" onClick={() => setOpen(false)}>&times;</span>
                <h1>{title}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="item">
                        <label>Nom :</label>
                        <input
                            {...register("name")}
                            type="text"
                            placeholder="Nom"
                        />
                        {errors.name && <span className="error">{errors.name.message}</span>}
                    </div>

                    <div className="item">
                        <label>Indice de préférence :</label>
                        <input
                            {...register("preference_index")}
                            type="number"
                            placeholder="preference"
                        />
                        {errors.preference_index && <span className="error">{errors.preference_index.message}</span>}
                    </div>

                    <div className="item">
                        <label>Status :</label>

                        <input
                            {...register("status")}
                            type="number"
                            placeholder="status"
                        />
                        {errors.status && <span className="error">{errors.status.message}</span>}
                    </div>

                    <div className="item">
                        <label>Unité :</label>

                        <select {...register("organization_unit_id")}>
                            <option value="" selected={!actionTypeData}>Unité</option>
                            {organizationUnits.map((org) => (
                                <option key={org.id} value={org.id}>{org.name}</option>
                            ))}
                        </select>
                        {errors.organization_unit_id &&
                            <span className="error-message">{errors.organization_unit_id.message}</span>}
                    </div>

                    <button>{buttonText}</button>
                </form>
            </div>
        </div>
    );
}

export default ActionTypeFormModal;
