import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { fetchEntitiesData } from "../../../../utils/fetch";
import { displayError } from "../../../../utils/alerts";
import Loading from "../../../loading/Loading";
import {z} from "zod";

import '../formModal.scss';


export const UserFormModal = ({ title, buttonText, setOpen, userData, submitData }) => {

    // I defined the schema of the user form in the component because the login is dynamically
    // optional, depending on whether we are updating the user or creating a new one
    const userFormSchema = z.object({
        login: z.string()
            .max(45, { message: "Identifiant ne peut pas dépasser 45 caractères" })
            .min(!userData ? 1 : undefined, { message: "Identifiant est requis" })
            .optional(), // Make login optional when updating

        name: z.string()
            .nonempty({ message: "Nom est requis" })
            .max(45, { message: "Nom ne peut pas dépasser 45 caractères" }),

        email: z.string()
            .nonempty({ message: "Email est requis" })
            .email({ message: "Email invalide" })
            .max(255, { message: "Email ne peut pas dépasser 255 caractères" }),
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting }
    } = useForm({
        resolver: zodResolver(userFormSchema),
    });

    const [organizationUnits, setOrganizationUnits] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchEntitiesData('organization-units', ['id', 'name'])
            .then((data) => {
                setOrganizationUnits(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                displayError("Erreur de serveur");
            });
    }, []);

    useEffect(() => {
        if (userData) {
            setValue("name", userData.name);
            setValue("email", userData.email);
            userData.organization_units.forEach(unit => {
                setValue(`organizationUnits.${unit.id}`, true);
            });
        }
    }, [userData, setValue, organizationUnits]);

    const onSubmit = async (data) => {
        try {
            setOpen(false);
            const selectedUnits = organizationUnits.filter(unit => data.organizationUnits[unit.id]);
            const formData = {
                name: data.name,
                email: data.email,
                login: data.login,
                organization_units: selectedUnits.map(unit => unit.id),
            };
            await submitData(formData);
        } catch (error) {
            displayError("Une erreur de serveur est survenue");
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="form-modal">
            <div className="custom-modal">
                <span className="close" onClick={() => setOpen(false)}>&times;</span>
                <h1>{title}</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="item">
                        <label>Nom :</label>

                        <input
                            {...register("name")}
                            type="text"
                            placeholder="Nom"
                        />
                        {errors.name && <span className="error">{errors.name.message}</span>}
                    </div>

                    <div className="item">
                        <label>Email :</label>

                        <input
                            {...register("email")}
                            type="email"
                            placeholder="Email"
                        />
                        {errors.email && <span className="error">{errors.email.message}</span>}
                    </div>

                    {!userData && (
                        <div className="item">
                            <label>Identifiant :</label>

                            <input
                                {...register("login")}
                                type="text"
                                placeholder="Identifiant"
                            />
                            {errors.login && <span className="error">{errors.login.message}</span>}
                        </div>
                    )}


                    <div className="checkbox-list">
                        <label>Unités :</label>
                        {organizationUnits.map((organizationUnit) => (
                            <div className="checkbox" key={organizationUnit.id}>
                                    <input
                                        type="checkbox"
                                        value={organizationUnit.name}
                                        {...register(`organizationUnits.${organizationUnit.id}`)}
                                    />
                                    <span className="checkbox-text">{organizationUnit.name}</span>
                            </div>
                        ))}
                        {errors.organizationUnits && <span className="error">{errors.organizationUnits.message}</span>}
                    </div>

                    <button type="submit" disabled={isSubmitting}>{buttonText}</button>
                </form>
            </div>
        </div>
    );
};

export default UserFormModal;
