import React, {useState} from "react";
import {fieldDotParser} from "../../../utils/objects";

import '../single.scss'
import {UserFormModal} from "../../modal/formModal/user/UserFormModal";

const SingleUser = ({onUpdate, title, data, img}) => {

    const [open, setOpen] = useState(false)

    const updateEntity = (data) => {
        onUpdate(data)
    }

    return <div className="single">
        <div className="view">
            <div className="info">
                <div className="topInfo">
                    {img && <img src={img} alt=""/>}
                    <h1>{title}</h1>
                    <button onClick={() => setOpen(true)}>Modifier</button>
                </div>
                <div className="details">
                    <div className="item">
                        <span className="itemTitle">Id :</span>
                        <span className="itemValue">{data['id'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Nom :</span>
                        <span className="itemValue">{data['name'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Email :</span>
                        <span className="itemValue">{data['email'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Unités :</span>
                        <span className="itemValue">
                            {Array.isArray(data['organization_units']) && data['organization_units'].length !== 0 ?
                                data['organization_units'].map(entity => fieldDotParser(entity, 'name')).join(', ') :
                                'N/A'
                            }
                        </span>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="chart">

            </div>
        </div>
        <div className="activities">

        </div>
        { open && <UserFormModal setOpen={setOpen}
                                 userData={data}
                                 submitData={updateEntity}
                                 title="Modifier Utilisateur"
                                 buttonText="Modifier"/>
        }
    </div>
}

export default SingleUser;
