import * as z from 'zod';

export const organizationUnitFormSchema = z.object({
    organization_id: z.string()
        .nonempty({ message: "La structure est requise" }),

    name: z.string()
        .nonempty({ message: "Le nom est requis" })
        .max(45, { message: "Le nom ne peut pas dépasser 45 caractères" }),

    shortname: z.string()
        .nonempty({ message: "L'abréviation est requise" })
        .max(5, { message: "L'abréviation ne peut pas dépasser 5 caractères" })
});
