import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { menu } from '../../data/menu';
import './menu.scss'

export const Menu = () => {
    useEffect(() => {
        const listItems = document.querySelectorAll('.listItem');

        listItems.forEach(item => {
            const img = item.querySelector('img');
            const iconBase = item.getAttribute('data-icon');

            item.addEventListener('mouseenter', () => {
                img.src = `${iconBase}-light.svg`;
            });

            item.addEventListener('mouseleave', () => {
                img.src = `${iconBase}-dark.svg`;
            });
        });
    }, []);

    return (
        <div className="menu">
            {menu.map(item => (
                <div className="item" key={item.id}>
                    <span className="title">{item.title}</span>
                    {item.listItems.map(listItem => (
                        <Link to={listItem.url} className="listItem" key={listItem.id} data-icon={listItem.icon}>
                            <img src={`${listItem.icon}-dark.svg`} alt=""/>
                            <span className="listItemTitle">{listItem.title}</span>
                        </Link>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default Menu
