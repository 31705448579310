import React, { useState, useRef, useEffect } from 'react';
import './navbar.scss';
import { IMAGES_PATH } from "../../../paths/dirs/dirPaths";
import { useAuth } from "../../../auth/AuthProvider";
import {displaySuccess} from "../../../utils/alerts";

export const Navbar = () => {
    const { authUser, logout } = useAuth();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        logout();
        displaySuccess("Déconnexion réussie!")
        setDropdownOpen(false); // Close the dropdown when logging out
    };

    // Handle clicking outside of the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="adminNavbar">
            <div className="logo">
                <img src={`${IMAGES_PATH}/logo.svg`} alt="Logo" />
                <span>MarsImpact</span>
            </div>
            <div className="icons">
                <img src={`${IMAGES_PATH}/search.svg`} alt="" className="icon"/>
                <img src={`${IMAGES_PATH}/app.svg`} alt="" className="icon"/>
                <img src={`${IMAGES_PATH}/expand.svg`} alt="" className="icon"/>
                <div className="notification">
                    <img src={`${IMAGES_PATH}/notifications.svg`} alt="Notifications"/>
                    <span>1</span>
                </div>
                <div ref={dropdownRef} className="user" onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                    <img src={`${IMAGES_PATH}/noavatar.png`} alt="User avatar" />
                    <span>{authUser ? authUser.name : 'Guest'}</span>
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <button onClick={handleLogout}>Logout</button>
                        </div>
                    )}
                </div>
                <img src={`${IMAGES_PATH}/settings.svg`} alt="" className="icon"/>
            </div>
        </div>
    );
};

export default Navbar;
