import React, { createContext, useContext, useState, useEffect } from 'react';
import { login as authServiceLogin, logout as authServiceLogout, getAuthUser } from '../services/authService';
import { displayError } from '../utils/alerts';

/**
 * Context to manage authentication-related state and functions.
 * 
 * @type {React.Context}
 * @see useAuth
 * @see AuthProvider
 * 
 * @since 1.0.0
 */
const AuthContext = createContext();

/**
 * Custom hook for accessing authentication-related functionality and user state.
 * 
 * @returns {{authUser: Object|null, isLoading: boolean, login: Function, logout: Function}}
 * 
 * @example
 * const { user, login, logout } = useAuth();
 * 
 * @since 1.0.0
 */
export const useAuth = () => {
  return useContext(AuthContext);
};

/**
 * Provider component that manages authentication state and provides authentication-related functions to its children.
 * 
 * @component
 * @param {{children: React.ReactNode}} props  Component props.
 * @returns {React.ReactNode} The wrapped children components with authentication context.
 * 
 * @throws Will throw an error if login or logout fails.
 * @since 1.0.0
 */
export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAuthUser = async () => {
      try {
        const user = await getAuthUser();
        setAuthUser(user);
      } catch (error) {
        console.error(error.message)
      } finally {
        setIsLoading(false)
      }
    };

    fetchAuthUser();

  }, []);

  /**
   * Async function to log in the user.
   * 
   * @async
   * @function
   * @param {Object} credentials An object containing `username` and `password`.
   * @returns {Promise<{user: Object, message: string}>} An object with `user` and `message`.
   * 
   * @throws Will throw an error if login fails.
   * @since 1.0.0
   */
  const login = async (credentials) => {
    try {

      const { user, message } = await authServiceLogin(credentials);

      setAuthUser(user);

      return { user, message }

    } catch (error) {

      throw error;
    }
  };

  /**
   * Async function to log out the user.
   * 
   * @async
   * @function
   * @returns {Promise<{message: string}>} An object with `message`.
   * 
   * @throws Will throw an error if logout fails.
   * @since 1.0.0
   */
  const logout = async () => {
    try {
      const { message } = await authServiceLogout();

      setAuthUser(null);

      return { message };
    } catch (error) {

      throw new Error(error.message || 'Logout failed');
    }
  };

  return (
    <AuthContext.Provider value={{ authUser, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
