import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

import FilterBar from '../../../components/filter/filterBar/FilterBar.jsx';
import './landing.scss';
import { displayError } from '../../../utils/alerts';
import StatBox from '../../../components/stat/statBoxes/statBox/StatBox';

/**
 * Displays statistics with pagination, search, and filtering options.
 *
 * @component
 * @returns {JSX.Element} The rendered Landing component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function Landing() {
  // State Hooks
  const [statsData, setStatsData] = useState([]);
  const [statsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchValue, setSearchValue] = useState('');

  // Fetch data based on search value
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/statComponentsProperties`, {
          params: { search: searchValue },
        });
  
        setStatsData(response.data);
      } catch (error) {
        displayError('Error fetching data:' + error);
      }
    };
  
    fetchData();
  }, [searchValue]);

  // Handle filter change
  const handleFilterChange = (filters) => {
    setSelectedFilters(filters);
  };

  // Handle search value change and reset current page to 1
  const handleSearchChange = (searchValue) => {
    setSearchValue(searchValue);
    setCurrentPage(1);
  };

  // Handle page change and scroll to top
  const handlePageChange = (event, newPage) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setCurrentPage(newPage);
  };

  // Render stat rows based on current page and stat per page
  const renderStatRows = () => {
    const rows = [];
    const startIndex = (currentPage - 1) * statsPerPage;
    const endIndex = startIndex + statsPerPage;
    const slicedStatsData = statsData.slice(startIndex, endIndex);

    for (let index = 0; index < slicedStatsData.length; index += 2) {
      const stat1 = slicedStatsData[index];
      const stat2 = slicedStatsData[index + 1];

      rows.push(
        <div key={index} className="row">
          {stat1 && (
            <div className={`col-lg-6 col-12 mt-4 stat-container`}>
              {renderStatBox(stat1)}
            </div>
          )}
          {stat2 && (
            <div className={`col-lg-6 col-12 mt-4 stat-container d-flex justify-content-lg-end`}>
              {renderStatBox(stat2)}
            </div>
          )}
        </div>
      );
    }

    return rows;
  };

  // Render a single stat box
  const renderStatBox = (stat) => {
    return (
      <StatBox
        apiEndpoint={stat.api}
        name={stat.name}
        filters={selectedFilters}
        compatibleChartTypes={stat.compatibleChartTypes}
        activeType={stat.activeChartType}
        label={stat.label}
        types={stat.compatibleChartTypes}
      >
        {stat.title}
      </StatBox>
    );
  };

  // Render the Landing component
  return (
    <>
      <div className="container">

        {/* Filter Bar */}
        <FilterBar onFilterChange={handleFilterChange} onSearchChange={handleSearchChange} />
        <div className="stats mb-4">
          {statsData && statsData.length > 0 ? (
            <>
              {/* Render Stat Rows */}
              {renderStatRows()}

              {/* Pagination */}
              <div className='pagination-container'>
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <Stack spacing={2} justifyContent="center">
                      <Pagination
                        count={Math.ceil(statsData.length / statsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                      />
                    </Stack>
                  </div>
                </div>
              </div>

            </>
          ) : (

            // No Landing Message
            <div className="no-stats-message text-center mt-5">
              <p className="mb-3">Pas de statistiques disponibles à afficher</p>
              <img
                width="80px"
                height="80px"
                src="images/no-data-icon.png"
                alt="No Statistics"
                className="img-fluid"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Landing;
