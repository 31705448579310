/**
 * Filter an object based on specific fields.
 *
 * @param {object} object - The object to filter.
 * @param {string[]} fields - The fields that we want to keep in the object.
 * @returns {*} The filtered object.
 * @since 1.0.0
 */
export const filterObject = (object, fields) => {
    if (!object) return null;
    const filteredData = {};
    fields.forEach(field => {
        filteredData[field] = fieldDotParser(object, field);
    });
    return filteredData;
};

/**
 * Parses a nested field from an object/entity using dot notation.
 * @param {object} entity - The object to parse.
 * @param {string} field - The field string representing the nested path, using dot notation.
 * @returns {*} The value of the nested field if found, otherwise undefined.
 */
export const fieldDotParser = (entity, field) => {
    let res = entity;
    field.split('.').forEach(fieldPart => {
        if (res && typeof res === 'object') {
            res = res[fieldPart];
        } else {
            res = undefined;
        }
    });
    return res;
};

/**
 * Check if an object is empty.
 *
 * This function checks if an object has no own enumerable properties.
 *
 * @param {object} obj - The object to check.
 * @returns {boolean} True if the object has no keys, otherwise false
 * @since 1.0.0
 */
export const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
};

/**
 * Check if an element is an object
 *
 * @param x
 * @returns {boolean}
 * @since 1.0.0
 */
export const isObject = (x) => {
    return (typeof x === 'object' && x !== null)
}