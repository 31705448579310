import React from 'react';
import NumberRangeSlider from '../../../input/numberRangeSlider/NumberRangeSlider';

/**
 * Displays a number range slider filter using the NumberRangeSlider component.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.filterOption - The filter option configuration.
 * @param {Object} props.filterOption.options - The options for configuring the number range slider.
 * @returns {JSX.Element} - The rendered NumberRangeSliderFilter component.
 *
 * @since 1.0.0
 */
const NumberRangeSliderFilter = ({ filterOption }) => (
  <div className="col-lg-3 col-6 mb-3">
    <NumberRangeSlider
      minLeftBoundValue={filterOption.options ? parseInt(filterOption.options.leftBoundValue) : 0}
      maxRightBoundValue={filterOption.options ? parseInt(filterOption.options.rightBoundValue) : 50}
      leftBoundLabel="Age min"
      rightBoundLabel="Age max"
    />
  </div>
);

export default NumberRangeSliderFilter;
