import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';

import "./Login.css"
import { displaySuccess } from '../../../utils/alerts';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [credentials, setCredentials] = useState({ login: '', password: '' });
    const [errors, setErrors] = useState({ login: '', password: '' });
    const [loginError, setLoginError] = useState(null);
    const [isLoginDisabled, setIsLoginDisabled] = useState(false);

    const handleLogin = async () => {
        try {
            const { message } = await login(credentials);
            setLoginError(null);
            displaySuccess(message)
            navigate('/');
        } catch (error) {
            setLoginError(null)
            if (error.status === 422) {
                setErrors(error.data.errors);
            } else if (error.status === 401) {
                setLoginError(error.data.message)
            } else if (error.status === 429) {
                if (!isLoginDisabled) {
                    setIsLoginDisabled(true);
                    setLoginError(error.data.message);
                    setTimeout(() => setIsLoginDisabled(false), 60000);
                }
                setLoginError(error.data.message)
            } else {
                setLoginError('Une erreur est survenue. Veuillez réessayer.');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        handleLogin()
    };

    useEffect(() => {
        if (loginError) {
            setErrors({});
        }
    }, [loginError]);

    return (
        <div className="container mt-5">
            {loginError && <div className="alert alert-danger">{loginError}</div>}
            <div className="login-box d-flex justify-content-center">
                <div className="login-card shadow-sm p-4">
                    <h2>Connexion</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">
                                Identifiant :
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                value={credentials.login}
                                onChange={(e) => setCredentials({ ...credentials, login: e.target.value })}
                                required
                            />
                            {errors.login && <div className="text-danger">{errors.login[0]}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Mot de passe :
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                value={credentials.password}
                                onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                                required
                            />
                            {errors.password && <div className="text-danger">{errors.password[0]}</div>}
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Connexion
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
