import React from 'react'
import Navbar from "../../components/navbar/mainNavbar/navbar/Navbar";
import {Outlet} from "react-router-dom";

export const MainLayout = () => {
    return (
        <div className="main">
            <Navbar />
            <Outlet/>
        </div>

    )
}

export default MainLayout
