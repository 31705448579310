import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {fetchEntityData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/organization";
import {isEmpty} from "../../../utils/objects";
import api from "../../../utils/api";
import {ORGANIZATIONS_PATH} from "../../../paths/routes/admin/routePaths";
import { useNavigate } from 'react-router-dom'
import SingleOrganization from "../../../components/single/organization/SingleOrganization";

import './organization.scss'

const Organization = () => {

    let { id } = useParams();
    const [organizationData, setOrganizationData] = useState({})
    const navigate = useNavigate()

    const updateOrganization = (data) => {
        if (!isEmpty(data)) {
            const dataToSend = {...organizationData, ...data}
            api.put(`organizations/${id}`, dataToSend).then(r => {
                setOrganizationData(r.data.organization)
                displaySuccess(r.data.message)
            }).catch(e => {
                if (e.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    switch(e.response.status) {
                        case 500:
                            displayError("Une erreur de serveur est survenue")
                            break;
                        case 422:
                            displayError("Les informations saisies ne sont pas valides");
                            break;
                        default:
                            displayError("Une erreur inattendue est survenue");
                    }
                } else {
                    displayError("Une erreur est survenue");
                }
            });
        }
    }

    useEffect(() => {
        fetchEntityData('organizations', id, fields).then(organization => {
            setOrganizationData(organization)
        }).catch(error => {
            navigate(ORGANIZATIONS_PATH);
            displayError(error.response.data)
        })
    }, []);

    return (
        <div className="organization"><SingleOrganization title={organizationData.name}
                                              onUpdate={updateOrganization}
                                              data={organizationData}/></div>
    )
}
export default Organization
