import React, {useEffect, useState} from 'react';
import './organizations.scss';
import {DataTable} from "../../../components/dataTable/DataTable";
import {fetchEntitiesData} from "../../../utils/fetch";
import {displayError, displaySuccess} from "../../../utils/alerts";
import {fields} from "../../../data/organization";
import api from "../../../utils/api";
import OrganizationFormModal from "../../../components/modal/formModal/organization/OrganizationFormModal";
import Loading from "../../../components/loading/Loading";

const columns = [
    { id: 1, type: "number", field: "id", headerName: "ID", width: 90 },
    {
        id: 2,
        field: 'name',
        type: 'text',
        headerName: 'Nom',
        width: 150,
    },
    {
        id: 3,
        field: 'shortname',
        type: 'text',
        headerName: 'Abbrév.',
        width: 150,
    },
    {
        id: 4,
        field: 'pac',
        type: 'text',
        headerName: 'PAC',
        width: 150,
    },
    {
        id: 5,
        field: 'postcode',
        type: 'text',
        headerName: 'Code Postal',
        width: 100,
    }
];

export const Organizations = () => {
    const [open, setOpen] = useState(false)
    const [organizationsData, setOrganizationsData] = useState([])
    const [loading, setLoading] = useState(false)

    const addOrganization = async (data) => {
        try {
            setLoading(true);

            const response = await api.post('organizations', data);

            setOrganizationsData(prevData => [...prevData, response.data.organization]);

            setLoading(false);
            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);

            // Handle errors
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    const deleteOrganization = async (id) => {
        try {
            setLoading(true);

            const response = await api.delete(`organizations/${id}`);

            if (response.data.success) {
                setOrganizationsData(prevData => prevData.filter(organization => organization.id !== id));
            }

            setLoading(false);
            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);

            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };


    useEffect(() => {
        fetchEntitiesData('organizations', fields).then(organizations => {
            setOrganizationsData(organizations)
        }).catch(error => displayError(error.response))
    }, []);

    if (loading) {
        return <Loading/>
    }

    return (
        <div className="organizations">
            <div className="info">
                <h1>Structures</h1>
                <button onClick={()=>setOpen(true)}>Ajouter Structure</button>
            </div>
            <DataTable onDelete={deleteOrganization} slug="organizations" rows={organizationsData} columns={columns}/>
            { open && <OrganizationFormModal setOpen={setOpen}
                                             submitData={addOrganization}
                                             title="Ajouter Structure"
                                             buttonText="Ajouter"/>
            }
        </div>
    )
}

export default Organizations
