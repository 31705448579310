import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

import './barChartBox.scss';

const monthNames = [
    'JAN', 'FÉV', 'MAR', 'AVR', 'MAI', 'JUN',
    'JUL', 'AOÛ', 'SEP', 'OCT', 'NOV', 'DÉC'
];

/**
 * Displays a chart box with a bar chart rendered using Chart.js.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.api - The API endpoint to fetch data for the chart.
 * @param {string} props.id - The unique identifier for the chart box.
 * @param {string} props.title - The title of the chart.
 * @returns {JSX.Element} - The rendered BarChartBox component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function BarChartBox({ data, id, title, label }) {
    const canvasRef = useRef(null);

    useEffect(() => {
        if (canvasRef.current && data) {
            renderBarChart(data, id, title, null);
        }

        // Clean up existing Chart.js instance when the component unmounts
        return () => {
            if (canvasRef.current) {
                const ctx = canvasRef.current.getContext('2d');
                const chart = Chart.getChart(ctx);
                if (chart) {
                    chart.destroy();
                }
            }
        };
    }, [data, title, id]);


    /**
     * Renders the provided data in the bar chart.
     *
     * @param {Object} data - The data to put in the chart.
     * @param {string} canvasId - The ID of the canvas element representing the chart.
     * @param {string} label - The label for the chart.
     * @param {Array} xLabels - The labels for the X-axis (optional).
     *
     * @since 1.0.0
     */
    const renderBarChart = (data, canvasId, label, xLabels) => {
        let backgroundColor, borderColor;

        backgroundColor = [
            'rgba(0, 123, 255, 0.4)',
            'rgba(40, 167, 69, 0.4)',
            'rgba(255, 193, 7, 0.4)',
            'rgba(255, 61, 127, 0.4)',
            'rgba(75, 192, 192, 0.4)',
            'rgba(153, 102, 255, 0.4)',
            'rgba(255, 159, 64, 0.4)',
            'rgba(201, 203, 207, 0.4)',
            'rgba(242, 95, 92, 0.4)',
            'rgba(0, 80, 157, 0.4)',
            'rgba(113, 238, 201, 0.4)',
            'rgba(255, 114, 118, 0.4)'
        ];
        borderColor = [
            'rgb(0, 123, 255)',
            'rgb(40, 167, 69)',
            'rgb(255, 193, 7)',
            'rgb(255, 61, 127)',
            'rgb(75, 192, 192)',
            'rgb(153, 102, 255)',
            'rgb(255, 159, 64)',
            'rgb(201, 203, 207)',
            'rgb(242, 95, 92)',
            'rgb(0, 80, 157)',
            'rgb(113, 238, 201)',
            'rgb(255, 114, 118)'
        ];

        let ctx = canvasRef.current.getContext('2d');

        // Destroy existing Chart.js instance if it exists
        const existingChart = Chart.getChart(ctx);

        if (existingChart) {
            existingChart.destroy();
        }

        // Create a new Chart instance using the data state
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: xLabels ?? Object.keys(data),
                datasets: [{
                    label: label,
                    data: Object.values(data),
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                }
            }
        });
    };

    return (
        <div className="bar-chart-container">
            <canvas id={id} ref={canvasRef}></canvas>
        </div>
    );
}

export default BarChartBox;
