import React from 'react'
import Navbar from "../../components/navbar/adminNavbar/Navbar";
import {Outlet} from "react-router-dom";
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/admin/Footer";
import './adminLayout.scss';

export const AdminLayout = () => {
    return (
        <div className="admin">
            <div className="admin-navbar-container">
                <Navbar />
            </div>
            <div className="admin-main-container">
                <div className="menuContainer">
                    <Menu/>
                </div>
                <div className="contentContainer">
                    <div className="main-content">
                        <Outlet/>
                    </div>
                </div>
            </div>
            {/*<div className="admin-footer-container">*/}
            {/*    <Footer/>*/}
            {/*</div>*/}
        </div>
    )
}

export default AdminLayout
