import React from "react";
import {DotLoader} from "react-spinners";

import "./loading.scss"

/**
 * Displays a loading spinner.
 *
 * @component
 * @returns {JSX.Element} - The rendered Loading component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
const Loading = () => {
    return (
        <div className="loading">
            <DotLoader color={'#3498db'} size={80}/>
        </div>
    )
}


export default Loading;