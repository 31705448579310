import React, { useState, useCallback, useEffect } from 'react';
import './roles.scss';
import RolesTree from "../../../components/roles/rolesTree/RolesTree";
import { fetchEntityData } from "../../../utils/fetch";
import { fields } from "../../../data/role";
import { displayError, displaySuccess } from "../../../utils/alerts";
import RoleDetails from "../../../components/roles/roleDetails/RoleDetails";
import api from "../../../utils/api";

export const Roles = () => {
    const [selectedRole, setSelectedRole] = useState(null);
    const [loading, setLoading] = useState(false);
    const [rolesTreeData, setRolesTreeData] = useState([]); // Initialize tree data state

    useEffect(() => {
        setLoading(true);

        // Fetch initial roles tree data
        api.get('roles/jsTree')
            .then(response => {
                setRolesTreeData(response.data.rolesTree);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                displayError(error.response);
            });
    }, []);

    const addRole = async (roleData) => {
        setLoading(true);

        const dataToSend = {
            ...roleData,
            organization_id: selectedRole.organization.id,
            parent_id: selectedRole.id
        };

        try {
            const response = await api.post(`roles`, dataToSend);
            setLoading(false);

            // Update roles tree data after adding a role
            const newRole = {
                id: response.data.role.id,
                parent: response.data.role.parent_id,
                text: response.data.role.name
            }
            setRolesTreeData(prevData => [...prevData, newRole]);

            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    case 422:
                        displayError("Les informations saisies ne sont pas valides");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                console.log(e);
                displayError("Une erreur est survenue");
            }
        }
    };

    const deleteRole = async (id) => {
        setLoading(true);
        try {
            const response = await api.delete(`roles/${id}`);
            setLoading(false);

            if (response.data.success) {
                // Remove deleted role from roles tree data
                setRolesTreeData(prevData => prevData.filter(role => role.id !== id));
            }

            displaySuccess(response.data.message);
        } catch (e) {
            setLoading(false);
            if (e.response) {
                switch (e.response.status) {
                    case 500:
                        displayError("Une erreur de serveur est survenue");
                        break;
                    default:
                        displayError("Une erreur inattendue est survenue");
                }
            } else {
                displayError("Une erreur est survenue");
            }
        }
    };

    const handleRoleClick = useCallback((roleId) => {
        fetchEntityData('roles', roleId, fields)
            .then(role => setSelectedRole(role))
            .catch(error => displayError(error.response.data));
    }, []);

    return (
        <div className="roles-container">
            <header className="roles-header">
                <h1>Roles</h1>
            </header>
            <main className="roles-main">
                <div className="roles-tree-container">
                    <RolesTree onRoleClick={handleRoleClick} rolesTreeData={rolesTreeData} />
                </div>
                <div className="role-details-container">
                    <RoleDetails role={selectedRole} addRole={addRole} deleteRole={deleteRole} />
                </div>
            </main>
        </div>
    );
};

export default Roles;
