import Swal from 'sweetalert2';

/**
 * Display an error message using SweetAlert2.
 *
 * @param {string} message - The error message to display.
 * @since 1.0.0
 */
const displayError = (message) => {
    Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
    });
}

/**
 * Display a success message using SweetAlert2.
 *
 * @param {string} message - The success message to display.
 * @since 1.0.0
 */
const displaySuccess = (message) => {
    Swal.fire({
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500 //  in ms
    });
}

export { displayError, displaySuccess };
