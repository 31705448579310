import React, { useState } from "react";
import '../single.scss'
import {fieldDotParser} from "../../../utils/objects";
import SiteFormModal from "../../modal/formModal/site/SiteFormModal";

const SingleSite = ({onUpdate, title, data}) => {

    const [open, setOpen] = useState(false)

    const updateEntity = (data) => {
        onUpdate(data, setOpen)
    }

    return <div className="single">
        <div className="view">
            <div className="info">
                <div className="topInfo">
                    <h1>{title}</h1>
                    <button onClick={() => setOpen(true)}>Modifier</button>
                </div>
                <div className="details">

                    <div className="item">
                        <span className="itemTitle">Id :</span>
                        <span className="itemValue">{data['id'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Nom :</span>
                        <span className="itemValue">{data['name'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Ville :</span>
                        <span className="itemValue">{fieldDotParser(data['city'], 'name') ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Indice de préférence :</span>
                        <span className="itemValue">{data['preference_index'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Status :</span>
                        <span className="itemValue">{data['status'] ?? 'N/A'}</span>
                    </div>

                    <div className="item">
                        <span className="itemTitle">Priorité :</span>
                        <span className="itemValue">{data['priority'] ?? 'N/A'}</span>
                    </div>

                </div>
            </div>
            <hr/>
            <div className="chart">

            </div>
        </div>
        <div className="activities">

        </div>
        {open && <SiteFormModal setOpen={setOpen}
                                siteData={data}
                                submitData={updateEntity}
                                title="Modifier Ville"
                                buttonText="Modifier"/>
        }
    </div>
}

export default SingleSite;
