import React from 'react';
import { displayError, displaySuccess } from '../../../../utils/alerts';
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../auth/AuthProvider';

/**
 * Renders a navigation list for the Navbar.
 *
 * @component
 * @returns {JSX.Element} - The rendered NavbarItems component.
 *
 * @author Elio Bteich
 * @since 1.0.0
 */
function NavbarItems() {
	const { authUser, isLoading, logout } = useAuth()

	const handleLogout = async () => {
		try {

			const { message } = await logout();

			displaySuccess(message)

		} catch (error) {
			console.error('Logout error:', error);
			displayError('An error occurred during logout.');
		}
	};


	return (
		<ul className="navbar-nav ms-auto">
			{authUser && !isLoading && (
				<li className="nav-item">
					<button onClick={handleLogout} className="nav-link">
						Déconnexion
					</button>
				</li>
			)}
		</ul>
	);
}

export default NavbarItems;
